import React from "react";
import { graphql } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import "../styles/pages/policy.scss";

const Policy = ({ data }) => {
  const { page } = data;
  return (
    <div className="policy">
      <Seo page={page} />
      <Layout>
        <div className="max-width policy-container">
          <h1>{data.page.data.title.text}</h1>
          <PrismicRichText field={data.page.data.content.richText} />
        </div>
      </Layout>
    </div>
  );
};

export const query = graphql`
  query PolicyPageQuery($lang: String, $id: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicPolicies(lang: { eq: $lang }, id: { eq: $id }) {
      url
      type
      data {
        meta_title
        meta_description
        content {
          richText
        }
        title {
          text
        }
      }
    }
  }
`;

export default Policy;
